@use '@angular/material' as mat;
@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

/* Define specific pallets for Prince Theme with exact colors definition from mockups*/
$prince-blue: ( 50: #ffffff, 100: #dde6f3, 200: #b4c9e4, 300: #7fa3d1, 400: #6992c9, 500: #5282c1, 600: #4072b4, 700: #38649d, 800: #305687, 900: #003a71, A100: #ffffff, A200: #dde6f3, A400: #6992c9, A700: #38649d, contrast: ( 50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: white, 900: white, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: white, ) );
$prince-red: ( 50: #ffebee, 100: #ffcdd2, 200: #ef9a9a, 300: #e57373, 400: #ef5350, 500: #f44336, 600: #e53935, 700: #d32f2f, 800: #c62828, 900: #b62a39, A100: #ff8a80, A200: #ff5252, A400: #ff1744, A700: #d50000, contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $dark-primary-text, 400: $dark-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text, ) );
$prince-brown: ( 50: #F4F0E5, 100: #d7ccc8, 200: #bcaaa4, 300: #a1887f, 400: #8d6e63, 500: #795548, 600: #6d4c41, 700: #5d4037, 800: #4e342e, 900: #3e2723, A100: #d7ccc8, A200: #bcaaa4, A400: #8d6e63, A700: #5d4037, contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $dark-primary-text, A400: $light-primary-text, A700: $light-primary-text, ) );

/*Define default font for Prince Project*/
$custom-typography: mat.define-typography-config($font-family: 'Lato, sans-serif', $headline-5: mat.define-typography-level(22px, 12px, 100));
@include mat.all-component-typographies($custom-typography);

/*Define and overrides headers styles*/
$custom-headers-typography: mat.define-typography-config($font-family: 'EB Garamond, Baskerville', $headline-5: mat.define-typography-level(22px, 12px, 100) );
@include mat.typography-hierarchy($custom-headers-typography);

$prince-app-primary: mat.define-palette($prince-blue, 900);
$prince-app-accent: mat.define-palette($prince-brown, 50);
$prince-app-warn: mat.define-palette($prince-red, 900);

$prince-app-theme: mat.define-light-theme((
        color: (
            primary: $prince-app-primary, 
            accent: $prince-app-accent, 
            warn: $prince-app-warn
        ),
        typography: $custom-typography,
        density: 0,
));

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($prince-app-theme);
