
$blue: #003a71;
$gray60: #999;
$gray80: #ccc;
$gray94: #f0f0f0;
$red: #a41f35;
$gold: #dfb63b;

$padding-left: 40px;
$border-radius: 30px;
$input-text-height: 32px;
$input-date-height: 32px;
$select-height: 34px;

/* MODAL STYLES
-------------------------------*/
jw-modal {
    /* modals are hidden by default */
    display: none;

    .jw-modal {
        /* modal container fixed across whole screen */
        position: fixed;
        top: 50%;
        transform: translateY(-50%) translateX(50%);
        width: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        /* z-index must be higher than .jw-modal-background */
        z-index: 1000;
        /* enables scrolling for tall modals */
        overflow: auto;

        .jw-modal-body {
            padding: 20px;
            background: white;
            /* margin exposes part of the modal background */
            margin: 40px;
            border-radius: $border-radius;
        }
    }

    .jw-modal-background {
        /* modal background fixed across whole screen */
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* semi-transparent black  */
        background-color: #ff0000;
        opacity: 0.75;
        /* z-index must be below .jw-modal and above everything else  */
        z-index: 900;
    }
}

body.jw-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}

div.table-full {
    display: table;
    width: 100%;
    padding: 10px;
    max-width: 100%
}

table {
    width: 100%;
    table-layout: fixed;
    word-break: break-word;
}

table th{
    /*padding: 15px 0px 0px 20px;*/
}

div.table-full td {
    padding: 5px;
}

div.row-full {
    display: table-row;
    width: 100%;
}

div.col-full {
    display: table-cell;
}

.pad20 {
    padding: 20px;
}

.w50 {
    width: 50%;
}

.w100 {
    width: 100%;
}

a{
    text-decoration: none;
}

.text-white{
    color: #fff;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.noMarg {
    margin: 0px !important;
}

.noPad {
    padding: 0px !important;
}

.panelLink {
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 10px;
    background-color: #ccc;
}

.panelLink a {
    color: #fff;
    text-decoration: none;
}

.panelContent {
    padding: 20px;
}

#tblInternDetails {
    border-spacing: 5px;
}

#tblInternDetails td {
    vertical-align: top;
}

#tblInternDetails input, #tblInternDetails select, #tblInternDetails textarea, #tblInternDetails button {
    border: solid 1px #ccc;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin: 5px;
}

.glyph1 {
    font-size: 34px;
    font-weight: 100;
    color: violet;
    margin: 20px;
}

.btnRed {
    border: solid 1px #ccc;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
    margin: 5px 20px 5px 20px;
    background-color: darkred;
    color: #fff;
    cursor: pointer;
}

.btnBlue {
    border: solid 1px #ccc;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
    margin: 5px 20px 5px 20px;
    background-color: darkblue;
    color: #fff;
    cursor: pointer;
}

.jumbotron {
    background-color: #eee;
    padding: 20px;
    color: #808080;
    margin: 20px;
    border-radius: 10px;
    font-weight: 500;
}

.darkBlueLink {
    color: darkblue;
    font-weight: 600;
    text-decoration: none;
}

.darkBlueHead{
    background-color: darkblue;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}

INPUT.GENERAL_INFO_INPUT, SELECT.GENERAL_INFO_INPUT {
    border: 1px solid #555;
    border-radius: 15px;
    height: 20px;
    max-width: 100%;
    padding: 3px;
    margin: 10px;
}

SELECT.GENERAL_INFO_INPUT{
    height: 30px;
}

.STD_INDENT {
    margin-left: 15px;
}

.interndetailsfont {
    font-family: serif;
    font-size: 16px !important;
}

div.table-full {
    display: table;
    width: 100%;
    padding: 10px;
    max-width: 100%
}

.tblManage th, .tblManage td {
    vertical-align: middle;
}

table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
}

table th, table td {
    /*padding: 10px 15px 10px 15px;
    vertical-align: top;*/
}

table.table-striped tr:nth-child(even), div.div-striped:nth-child(even) {
    background-color: #fff;
}

div.table-full td {
    padding: 5px;
}

div.row-full {
    display: table-row;
    width: 100%;
}

div.col-full {
    display: table-cell;
}

div.inline{
    display: inline-block;
}

.pad20 {
    padding: 20px;
}

.pad10 {
    padding: 10px;
}

.marg5{
    margin: 5px;
}

.indent10{
    padding-left: 10px !important;
}

.indent20 {
    padding-left: 20px !important;
}

.wAuto {
    width: Auto;
}

.w50 {
    width: 50%;
}

.w100 {
    width: 100%;
}

a{
    text-decoration: none;
}

.text-white{
    color: #fff;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.fontsz12 {
    font-size: 12px;
}

.fontsz14 {
    font-size: 14px;
}

.noMarg {
    margin: 0px !important;
}

.noPad {
    padding: 0px !important;
}

.panelLink {
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 10px;
    background-color: #ccc;
}

.panelLink a {
    color: #fff;
    text-decoration: none;
}

.panelContent {
    padding: 20px;
}

#tblInternDetails {
    border-spacing: 5px;
}

#tblInternDetails td {
    vertical-align: top;
}

#tblInternDetails input, #tblInternDetails select, #tblInternDetails textarea, #tblInternDetails button {
    border: solid 1px #ccc;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin: 5px;
}

.glyph1 {
    font-size: 34px;
    font-weight: 100;
    color: violet;
    margin: 20px;
}

.btnRed {
    border: solid 1px #ccc;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
    margin: 5px 20px 5px 20px;
    background-color: darkred;
    color: #fff;
    cursor: pointer;
}

.btnBlue {
    border: solid 1px #ccc;
    border-radius: 20px;
    padding: 10px 15px 10px 15px;
    margin: 5px 20px 5px 20px;
    background-color: darkblue;
    color: #fff;
    cursor: pointer;
}

.jumbotron {
    background-color: #eee;
    padding: 20px;
    color: #808080;
    margin: 20px;
    border-radius: 10px;
    font-weight: 500;
}

.darkBlueLink {
    color: darkblue;
    font-weight: 600;
    text-decoration: none;
}

.darkBlueHead{
    background-color: darkblue;
    color: #fff;
    padding: 10px 0px 10px 0px;
}

/*input.form-control, select.form-control {
    border: 1px solid #555;
    border-radius: 15px;
    max-width: 100%;
    padding: 4px 6px 4px 6px;
    margin: 5px;
}*/

select.form-control{
    height: 30px;
}

input.form-control {
    height: 20px;
}

.pointer{
    cursor: pointer;
}

.statusMsg1 {
    text-align: center;
    font-size: 24px;
    padding-top: 60px;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger button {
    color: #721c24;
    font-weight: bold;
}

.error-message {
    color: red !important;
    font-size: small !important;
}

.mat-mdc-button {
    letter-spacing: normal;
}

.mat-mdc-header-cell{
    line-height: 1em;
}

.mat-mdc-cell {
    line-height: 1.25em;
}

.cdk-overlay-pane {
    min-width: fit-content;
}

.mat-mdc-menu-panel {
    max-width: fit-content !important;
}